"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceEventTypes = exports.HttpStatusCode = exports.UserGlobalPermission = exports.DeviceType = exports.AcceptableTag = exports.RecordingProcessingState = exports.TagMode = exports.RecordingPermission = exports.RecordingType = void 0;
var RecordingType;
(function (RecordingType) {
    RecordingType["ThermalRaw"] = "thermalRaw";
    RecordingType["Audio"] = "audio";
    RecordingType["TrailCamImage"] = "trailcam-image";
    RecordingType["TrailCamVideo"] = "trailcam-video";
    RecordingType["InfraredVideo"] = "irRaw";
})(RecordingType = exports.RecordingType || (exports.RecordingType = {}));
var RecordingPermission;
(function (RecordingPermission) {
    RecordingPermission["DELETE"] = "delete";
    RecordingPermission["TAG"] = "tag";
    RecordingPermission["VIEW"] = "view";
    RecordingPermission["UPDATE"] = "update";
})(RecordingPermission = exports.RecordingPermission || (exports.RecordingPermission = {}));
var TagMode;
(function (TagMode) {
    TagMode["Any"] = "any";
    TagMode["UnTagged"] = "untagged";
    TagMode["Tagged"] = "tagged";
    TagMode["HumanTagged"] = "human-tagged";
    TagMode["AutomaticallyTagged"] = "automatic-tagged";
    TagMode["NoHuman"] = "no-human";
    TagMode["AutomaticOnly"] = "automatic-only";
    TagMode["HumanOnly"] = "human-only";
    TagMode["AutomaticHuman"] = "automatic+human";
    TagMode["AutomaticHumanUrlSafe"] = "automatic-and-human";
})(TagMode = exports.TagMode || (exports.TagMode = {}));
var RecordingProcessingState;
(function (RecordingProcessingState) {
    RecordingProcessingState["Corrupt"] = "CORRUPT";
    RecordingProcessingState["Tracking"] = "tracking";
    RecordingProcessingState["ReTrack"] = "retrack";
    RecordingProcessingState["AnalyseThermal"] = "analyse";
    RecordingProcessingState["Finished"] = "FINISHED";
    RecordingProcessingState["FinishedFailed"] = "FINISHED.failed";
    RecordingProcessingState["Analyse"] = "analyse";
    RecordingProcessingState["Reprocess"] = "reprocess";
    RecordingProcessingState["ReTrackFailed"] = "retrack.failed";
    RecordingProcessingState["TrackingFailed"] = "tracking.failed";
    RecordingProcessingState["AnalyseThermalFailed"] = "analyse.failed";
    RecordingProcessingState["AnalyseFailed"] = "analyse.failed";
    RecordingProcessingState["ReprocessFailed"] = "reprocess.failed";
    RecordingProcessingState["AnalyseTest"] = "analyse.test";
})(RecordingProcessingState = exports.RecordingProcessingState || (exports.RecordingProcessingState = {}));
var AcceptableTag;
(function (AcceptableTag) {
    AcceptableTag["Cool"] = "cool";
    AcceptableTag["RequiresReview"] = "requires review";
    AcceptableTag["Note"] = "note";
    AcceptableTag["InteractionWithTrap"] = "interaction with trap";
    AcceptableTag["MissedTrack"] = "missed track";
    AcceptableTag["MultipleAnimals"] = "multiple animals";
    AcceptableTag["TrappedInTrap"] = "trapped in trap";
    AcceptableTag["MissedRecording"] = "missed recording";
    AcceptableTag["DigitalTrigger"] = "trap triggered";
    AcceptableTag["Inside"] = "inside";
    AcceptableTag["Outside"] = "outside";
    AcceptableTag["Incursion"] = "incursion";
})(AcceptableTag = exports.AcceptableTag || (exports.AcceptableTag = {}));
var DeviceType;
(function (DeviceType) {
    DeviceType["Audio"] = "audio";
    DeviceType["Thermal"] = "thermal";
    DeviceType["TrailCam"] = "trailcam";
    DeviceType["TrapIrCam"] = "trapcam";
    DeviceType["Hybrid"] = "hybrid-thermal-audio";
    DeviceType["Unknown"] = "unknown";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
var UserGlobalPermission;
(function (UserGlobalPermission) {
    UserGlobalPermission["Write"] = "write";
    UserGlobalPermission["Read"] = "read";
    UserGlobalPermission["Off"] = "off";
})(UserGlobalPermission = exports.UserGlobalPermission || (exports.UserGlobalPermission = {}));
var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["Ok"] = 200] = "Ok";
    HttpStatusCode[HttpStatusCode["OkNoContent"] = 204] = "OkNoContent";
    HttpStatusCode[HttpStatusCode["MovedPermanently"] = 301] = "MovedPermanently";
    HttpStatusCode[HttpStatusCode["NotModified"] = 304] = "NotModified";
    HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
    HttpStatusCode[HttpStatusCode["AuthorizationError"] = 401] = "AuthorizationError";
    HttpStatusCode[HttpStatusCode["Forbidden"] = 403] = "Forbidden";
    HttpStatusCode[HttpStatusCode["Unprocessable"] = 422] = "Unprocessable";
    HttpStatusCode[HttpStatusCode["ServerError"] = 500] = "ServerError";
})(HttpStatusCode = exports.HttpStatusCode || (exports.HttpStatusCode = {}));
exports.DeviceEventTypes = [
    "alert",
    "attiny-sleep",
    "audioBait",
    "daytime-power-off",
    "powered-off",
    "power-on-test",
    "rpi-power-on",
    "salt-update",
    "systemError",
    "test",
    "throttle",
    "versionData",
    "config",
    "bad-thermal-frame",
    "stop-reported",
];
